import React from 'react'
import PropTypes from 'prop-types'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import classNames from 'classnames'

// Styles & Images
import 'src/components/assets/shared/scss/AssetItemIcon.scss'

// Main component:
const AssetItemIcon = ({ index, symbol, columnName, tooltipText, color }) => (
  <div className="x__asset-item-icon">
    {tooltipText ? (
      <OverlayTrigger
        key={`${symbol}-${columnName}`}
        placement="top"
        overlay={
          <Tooltip
            id={`tooltip-${symbol}-${columnName}`}
            className={classNames({ 'first-row-tooltip': index === 0 })}
          >
            {tooltipText}
          </Tooltip>
        }
      >
        <span className={`x__asset-item-icon--${color} x__asset-item-icon--tooltip`} />
      </OverlayTrigger>
    ) : (
      <span className={`x__asset-item-icon--${color}`} />
    )}
  </div>
)

// Main component props:
AssetItemIcon.propTypes = {
  index: PropTypes.number,
  symbol: PropTypes.string,
  columnName: PropTypes.string,
  tooltipText: PropTypes.string,
  color: PropTypes.oneOf(['blue', 'green', 'grey', 'orange', 'red']),
}

AssetItemIcon.defaultProps = {
  color: 'grey',
}

export default AssetItemIcon
