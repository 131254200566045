import classNames from 'classnames'
import React, { useState, useEffect } from 'react'
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync'

import AssetItem from 'components/assets/shared/AssetItem'
import AssetsSearch from 'components/assets/shared/AssetsSearch'
import AssetsSort from 'components/assets/shared/AssetsSort'
import TableFooter from 'components/assets/Table/TableFooter'
import TableHeader from 'components/assets/Table/TableHeader'
import searchAssets from 'js/utils/searchAssets'
import { isDev, LEDGER_WALLET_ENABLED } from 'src/constants'

import 'components/assets/Table/scss/Table.scss'

// Helpers:
const columnsData = [
  {
    section: 'Support',
    headings: [
      { type: 'platform', name: 'mobile', title: 'Mobile' },
      { type: 'platform', name: 'desktop', title: 'Desktop' },
      { type: 'platform', name: 'web3', title: 'Web3' },
      { type: 'platform', name: 'trezor', title: 'Trezor' },
      LEDGER_WALLET_ENABLED && { type: 'platform', name: 'ledger', title: 'Ledger' },
    ],
  },
  {
    section: 'Swap Status',
    headings: [
      { type: 'functionality', name: 'exchange', title: 'Swap' },
      { type: 'information', name: 'details', title: 'Details' },
    ],
  },
]

// Partials:
const Tooltip = ({ copy }) => {
  return (
    <div className="x__asset-table__tooltip-wrapper">
      <svg className="x__asset-table__tooltip" viewBox="0 0 18 18" fill="none">
        <circle cx="9" cy="9" r="9" fill="#1F2033" fillOpacity="0.1" />
        <g opacity="0.75">
          <circle cx="9.00001" cy="4.67979" r="0.9" fill="white" />
          <rect x="8.29285" y="6.47998" width="1.60714" height="7.875" rx="0.803571" fill="white" />
          <rect x="7.64999" y="6.47998" width="2.25" height="1.60714" rx="0.803571" fill="white" />
          <rect x="8.29285" y="12.748" width="2.25" height="1.60714" rx="0.803571" fill="white" />
        </g>
      </svg>

      {copy ? (
        <div className="x__asset-table__tooltip-show">
          <p>{copy}</p>
        </div>
      ) : null}
    </div>
  )
}

const Body = ({ assets, columns, sort, noTooltip, truncateList, showAll, searchActive, theme }) =>
  assets
    .sort((a, b) => {
      if (sort === 'apy') return (b.apy || 0) - (a.apy || 0)
      if (sort === 'marketcap') return (b.marketcap || 0) - (a.marketcap || 0)
      // Alphabetical:
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
    })
    .map((data, index) => {
      const props = {
        key: [index, data.ticker, data.network].filter((el) => !!el).join('-'),
        index,
        data,
        columns,
        noTooltip,
        theme,
      }

      if (truncateList) {
        if (index < 20 || showAll || searchActive) {
          return <AssetItem key={index} {...props} />
        }
      } else {
        return <AssetItem key={index} {...props} />
      }
      return null
    })

const ShowAllButton = ({ showAll, onClick }) => (
  <a
    data-toggle="collapse"
    role="button"
    className="x__asset-table__show-all x__button x__button--shadow-light"
    id="showAllbtn"
    onClick={onClick}
    aria-expanded={showAll}
  >
    <span className="x__button-align">
      <span className="x__button-align-text">See the full list</span>
    </span>
  </a>
)

// Main component:
const AssetsTable = ({
  data,
  assets = [],
  columns = columnsData,
  count = 0,
  disabled = false,
  noSorting = false,
  noTooltip = false,
  truncateList = false,
  theme = 'light',
}) => {
  const [searched, setSearched] = useState(assets)
  const [sort, setSort] = useState('marketcap')
  const [showAll, setShowAll] = useState(!truncateList)
  const [searchActive, setSearchActive] = useState(false)

  // prevent from crashing if there are no items.
  const hasItems = !!searched.length

  // search for items in the array.
  const handleSearch = (term) => {
    setSearched(searchAssets(assets, term))
    setSearchActive(!!term.trim().length)
  }

  // show full list if truncateList is set to true.
  const handleShowAll = (e) => {
    e.preventDefault()
    if (!showAll) setShowAll(true)
  }

  /*
   * Without initially setting and clearing search on resourceLoaded in this way,
   * causes asset list children to draw incorrectly, with logos swapped out
   * between assets, reason not known. Removing sort removes the need for this.
   */
  useEffect(() => handleSearch(''), [])

  // Update assets list.
  useEffect(() => setSearched(assets), [assets])

  // table Body props.
  const bodyProps = {
    assets: searched,
    columns,
    sort,
    truncateList,
    showAll,
    searchActive,
    noTooltip,
    theme,
  }

  return (
    <>
      <ScrollSync>
        <div id="x-asset-status-table-container" className="x__asset-table">
          <div id="assets" className="x__heading-container x-asset-status__heading">
            {isDev ? <pre>Dev only: {count} assets</pre> : null}
            {data.h2 ? (
              <div className="x__heading-wrapper">
                <h2
                  className={classNames(
                    'x__asset-table__heading',
                    `x__asset-table__heading--${theme}`,
                    { 'x__asset-table__heading--center': noSorting }
                  )}
                  dangerouslySetInnerHTML={{ __html: data.h2 }}
                />
                <Tooltip copy={data.tooltip} />
              </div>
            ) : null}
            <p
              className="x__asset-table__subheading"
              dangerouslySetInnerHTML={{ __html: data.copy }}
            />
          </div>

          {!disabled ? (
            noSorting ? (
              <div className="x__search-container x__search-container--nosorting">
                <AssetsSearch
                  data={data.filter}
                  size="larger"
                  onChange={(val) => handleSearch(val)}
                />
              </div>
            ) : (
              <div className="row">
                <div className="x__search-container col-md-8 col-sm-12">
                  <AssetsSearch data={data.filter} onChange={(val) => handleSearch(val)} />
                </div>
                <div className="x__sorting-container col-md-4 col-sm-12">
                  <AssetsSort data={data.filter.sorting} onChange={(val) => setSort(val)} />
                </div>
              </div>
            )
          ) : null}

          <div className={`x__asset-table__container x__asset-table__container--${theme}`}>
            <div className="x__header-container">
              <ScrollSyncPane>
                <div className="x__header-container__scroll">
                  <TableHeader columns={columns} theme={theme} />
                </div>
              </ScrollSyncPane>
            </div>

            <ScrollSyncPane>
              <div id="x-assets-list-table" className="x__list-container">
                {hasItems ? (
                  <Body {...bodyProps} />
                ) : (
                  <TableFooter copy={data.none} theme={theme} />
                )}
              </div>
            </ScrollSyncPane>
          </div>
        </div>
      </ScrollSync>

      {truncateList && !showAll && !searchActive ? <ShowAllButton onClick={handleShowAll} /> : null}
    </>
  )
}

export default AssetsTable
