import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import AssetIcon from 'src/components/assets/shared/AssetIcon'

import { SITE_URL } from 'src/constants'

// Styles & Images
import 'src/components/assets/shared/scss/AssetItemLabel.scss'

// Partials:
const Wrapper = ({ children, link, isCombined, theme }) => {
  /* Will return either a span or a, depending on 'link' */
  if (link) {
    return (
      <a
        href={`${SITE_URL}/${link}`}
        className={`x__asset-item-label x__asset-item-label--link x__asset-item-label--${theme}`}
      >
        {children}
      </a>
    )
  } else {
    return (
      <span
        className={classNames(`x__asset-item-label x__asset-item-label--${theme}`, {
          'x__asset-item-label--combined': isCombined,
        })}
      >
        {children}
      </span>
    )
  }
}

const Coin = ({ data }) => (
  <span
    className={classNames('x__coin', {
      'x__coin--new': data.new,
    })}
  >
    <span className="x__coin__name">{data.name}</span>{' '}
    <span className="x__coin__tag">{data.ticker}</span>
  </span>
)

const Network = ({ data }) => {
  if (!data.network && !data.isCombined) return null

  const label = data.isCombined
    ? `All (${data.combinedAssets.length})`
    : `${data.network}<span> Network</span>`
  const gradientBackground =
    !data.isCombined && data.networkColors
      ? {
          background: `linear-gradient(120deg, ${data.networkColors.gradient.map(
            (color, i) => `${color} ${(100 / (data.networkColors.gradient.length - 1)) * i}%`
          )})`,
          color: data.networkColors.text,
        }
      : {}

  return (
    <span
      className={classNames('x__network', { 'x__network--items': data.isCombined })}
      style={gradientBackground}
      dangerouslySetInnerHTML={{ __html: label }}
    />
  )
}

// Main component:
const AssetItemLabel = ({ data, theme }) => (
  <Wrapper link={data?.link} isCombined={data?.isCombined} theme={theme}>
    <AssetIcon {...data} />

    <span>
      <Coin data={data} />
      <Network data={data} />
    </span>
  </Wrapper>
)

// Main component props:
AssetItemLabel.propTypes = {
  data: PropTypes.object,
  theme: PropTypes.oneOf(['dark', 'light']),
}

AssetItemLabel.defaultProps = {
  theme: 'light',
}

export default AssetItemLabel
