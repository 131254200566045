import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Dropdown from 'react-bootstrap/Dropdown'

// Styles & Images
import 'src/components/assets/shared/scss/AssetsSort.scss'

// Main component:
const StatusItem = ({ data, onChange }) => {
  const [selectedSort, setSelectedSort] = useState('marketcap')
  const [selectedSortText, setSelectedSortText] = useState(data.marketcap)
  const handleSortItemClicked = (e, sort) => {
    setSelectedSortText(e.target.text)
    setSelectedSort(sort)
  }

  useEffect(() => onChange(selectedSort), [selectedSort])

  return (
    <div className="x__asset-table__sorting">
      <label>{data.label}</label>

      <Dropdown>
        <Dropdown.Toggle id="dropdown-basic" className="dropdown">
          {selectedSortText}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={(e) => handleSortItemClicked(e, 'alphabetic')}>
            {data.alphabetic}
          </Dropdown.Item>
          <Dropdown.Item onClick={(e) => handleSortItemClicked(e, 'marketcap')}>
            {data.marketcap}
          </Dropdown.Item>
          <Dropdown.Item onClick={(e) => handleSortItemClicked(e, 'apy')}>{data.apy}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

// Main component props:
StatusItem.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
}

StatusItem.defaultProps = {}

export default StatusItem
