export default (assetsArray, searchTerm) => {
  return assetsArray.filter((assetData) => {
    let assetString = `${assetData.name} ${assetData.ticker}`

    if (assetData.altNames) {
      assetString = `${assetString} ${assetData.altNames.join(' ')}`
    }

    if (assetData.network) {
      assetString = `${assetString} ${assetData.network}`
    }

    return assetString.toUpperCase().includes(searchTerm.trim().toUpperCase())
  })
}
