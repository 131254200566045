import React from 'react'
import PropTypes from 'prop-types'

// Styles & Images
import 'src/components/assets/Table/scss/TableFooter.scss'

// Main component:
const TableFooter = ({ className, copy, theme }) => (
  <div className={`x__asset-table__footer x__asset-table__footer--${theme}`}>
    <p className="x__message">{copy}</p>
  </div>
)

// Main component props:
TableFooter.propTypes = {
  className: PropTypes.string,
  copy: PropTypes.string,
  theme: PropTypes.oneOf(['dark', 'light']),
}

TableFooter.defaultProps = {
  theme: 'light',
}

export default TableFooter
