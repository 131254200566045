import classNames from 'classnames'
import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import 'components/assets/Table/scss/TableHeader.scss'

// Helpers:
const defaultColumns = [
  {
    type: 'default',
    name: 'index',
    title: 'Index',
  },
  {
    type: 'default',
    name: 'asset',
    title: 'Digital Asset',
  },
  {
    type: 'default',
    name: 'apy',
    title: 'APY Rewards',
  },
]

// Partials:
const InfoBubble = ({ data }) => (
  <a className="x__info" href={data.info} target="_blank" rel="noopener">
    <OverlayTrigger
      key={data.className}
      placement="top"
      overlay={<Tooltip id={`tooltip-${data.className}`}>Learn more</Tooltip>}
    >
      <span>&#9432;</span>
    </OverlayTrigger>
  </a>
)

const Item = ({ item, isFirst }) => {
  const title = item.section || item.title
  const info = item.info
  const className = item.section || item.name

  return (
    <span
      className={classNames('x__cell', `x__cell--${className.toLowerCase()}`, {
        'x__cell--first': isFirst,
      })}
    >
      {title}
      &nbsp;
      {!!info && <InfoBubble data={{ className, info }} />}
    </span>
  )
}

const DefaultItems = ({ items }) => (
  <div className="x__group x__group--default">
    {items.map((item) => (
      <Item key={item.name} item={item} />
    ))}
  </div>
)

const ColumnItems = ({ items }) => (
  <div className="x__group x__group--columns">
    {items.map((item) => (
      <Item key={item.name} item={item} />
    ))}
  </div>
)

const SectionColumnItems = ({ items }) =>
  items.map((item, i) => (
    <div key={item.section} className="x__group x__group--section">
      <div className="x__group__heading">
        <Item item={item} isFirst={i === 0} />
      </div>
      <div className="x__group--columns">
        {item.headings.map(
          (item, i) => !!item && <Item key={item.name} item={item} isFirst={i === 0} />
        )}
      </div>
    </div>
  ))

// Main component:
const TableHeader = ({ columns, theme = 'light' }) => {
  const hasSection = columns.some((col) => col.section)

  return (
    <div
      className={classNames('x__asset-table__header', `x__asset-table__header--${theme}`, {
        'x__asset-table__header--sections': hasSection,
      })}
    >
      <DefaultItems items={defaultColumns} />

      {hasSection ? <SectionColumnItems items={columns} /> : <ColumnItems items={columns} />}
    </div>
  )
}

export default TableHeader
